import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`CodeStory pushes the well-known sequence diagrams to the next level with rich content and Live Code Views popovers.`}</p>
  <div className="video" style={{
      lineHeight: 0
    }}>
  <video className="rounded-2xl overflow-clip my-shadow" muted width="100%" autoPlay={true} loop>
    <source src="https://s3.fr-par.scw.cloud/codestory-web/media/Diagram_2.mp4" type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video>
  </div>
    <p>{`A note can have a diagram associated to it. Let's create a blank note and add a diagram.`}</p>
    <h3 {...{
      "id": "create-a-note-and-add-a-diagram",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-note-and-add-a-diagram",
        "aria-label": "create a note and add a diagram permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Create a note and add a Diagram`}</h3>
    <p>{`In the Note Inspector use the Add Diagram button.`}</p>
    <MyImage src="diagram-1.png" alt="Diagram" style={{
      maxWidth: 120,
      margin: 'auto'
    }} mdxType="MyImage" />
    <h3 {...{
      "id": "start-by-adding-actors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#start-by-adding-actors",
        "aria-label": "start by adding actors permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Start by adding actors`}</h3>
    <MyImage src="diagram-2.png" alt="Diagram" mdxType="MyImage" />
    <h3 {...{
      "id": "add-some-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-some-steps",
        "aria-label": "add some steps permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Add some steps`}</h3>
    <p>{`Enjoy rich text editing...`}</p>
    <MyImage src="diagram-3.png" alt="Diagram" mdxType="MyImage" />
    <p>{`Add messages or notes. Use drag & drop to reorganize.`}</p>
    <MyImage src="diagram-4.png" alt="Diagram" mdxType="MyImage" />
    <h3 {...{
      "id": "link-to-other-notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#link-to-other-notes",
        "aria-label": "link to other notes permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Link to other notes`}</h3>
    <p>{`Let's add a link to our first step.`}</p>
    <MyImage src="diagram-6.png" alt="Diagram" mdxType="MyImage" />
    <p>{`Let's create a diagram child note with a Live Code View.`}</p>
    <p>{`Copy the token (or paste one if you want to point to an existing token) and press Create.`}</p>
    <p>{`This will open a "quick note" editor.`}</p>
    <MyImage src="diagram-7.png" alt="Diagram" mdxType="MyImage" />
    <p>{`Close when you're done, but you can make a longer note if you wish.`}</p>
    <MyImage src="diagram-8.png" alt="Diagram" mdxType="MyImage" />
    <p>{`Notice the blue disc. Hover over it to reveal the note we just created.`}</p>
    <MyImage src="diagram-9.png" alt="Diagram" mdxType="MyImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      